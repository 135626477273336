.projects-container{
	min-height: initial !important;
}

.projects-container .projects{
	margin: 40px 0;
}

.projects-container .page-padding{
	padding: 40px 0 !important;
}

.projects-container .project{
	position: relative;
}


.projects-container>.center-flex{
	align-items: flex-start;
}

.project-dialog .MuiDialog-paper{
	width: calc(80vh * 4/3);
	height: calc(80vh + 20px);
	max-width: 100% !important;
}

.project-dialog{
	width: 100%;
	height: 100%;
}

.projects .project{
	margin: 10px 0;
    width: 20%;
    height:250px;
    background-size: cover !important;

}

.project .play-button{
	padding: 0 !important;
}

.projects .project:hover .project-container, .projects .hover .project-container{
	opacity: 1;
}

.project .project-container{
	position: absolute;
	width: 100%;
	height: 100%;
    opacity: 0;
    transition: all 0.5s;
}

.project-container .play-button svg{
	font-size: 80px;
	color: rgba(250,250,250, 0.5);
}
.project-container .play-button:hover svg{
	color: rgba(250,250,250);
}

.projects .content-layout .app-subtitle{
	letter-spacing: 2px !important;
}


 .grade-button{
	margin: 0 10px;
	width: 150px;
	transition: background-color 1s;
}

 .grade-button:hover{
	color:white;
	border-color: white;
    background-color: rgba(0, 0, 0, 0.5) !important;
}
 .grade-button-active{
	color:white;
	border-color: white;
	background-color: rgba(0, 0, 0, 0.75);
}


@media screen and (max-width: 1200px) {
   .projects .project{
		width: 30%;
	}
}

@media screen and (max-width: 850px) {
   .projects .project{
		width: 45%;
	}
}

@media screen and (max-width: 800px) {
   .project-dialog .MuiDialog-paper{
		width: 90vw;
		height: calc( 90vw * 3/4 + 20px);
	}	
}

@media screen and (max-width: 650px) {
   .projects .project{
		width: 90%;
		height: 300px;
	}
}