.levels{
	align-items: flex-start;
}

/*.courses-grades .page-padding{
	padding: 20px !important;
}*/

.courses-grades .page-content{
	width: 100% !important;
}

.levels *{
	box-sizing: border-box;
}

.levels .level-content{
	padding: 20px !important;
	overflow-y: auto;
}

.levels .level-title-container{
	padding:20px;
	min-height: 150px;
}

.levels .level-content .app-description{
	letter-spacing: 0.5px;
	font-size: 13px;
	line-height: 20px;
}

.levels .level-content ul{  
	font-size: 13px;
	letter-spacing: 0.5px;
	font-weight: 600;
}



.levels .swiper-slide{
	padding: 10px;
	  display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
}

.level-container{
	flex: 25%;
}

.levels .level{
	height: 100%;
	 -webkit-transition: all 300ms ease-in;
	  -webkit-transform: scale(1);
	  -moz-transition: all 300ms ease-in;
	  -moz-transform: scale(1);
	  -ms-transition: all 300ms ease-in;
	  -ms-transform: scale(1);
	  transition: all 300ms ease-in;
	  transform: scale(1);
}


.levels .level:hover{
  -webkit-transition: all 300ms ease-in;
  -webkit-transform: scale(1.02);
  -moz-transition: all 300ms ease-in;
  -moz-transform: scale(1.02);
  -ms-transition: all 300ms ease-in;
  -ms-transform: scale(1.02);
  transition: all 300ms ease-in;
  transform: scale(1.02);
  z-index: 5;
}

.level .MuiListItem-root{
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.level .MuiListItemIcon-root{
	min-width: 45px;
}

.level .title{
	padding-left: 20px;
}

.level-media{
	height:225px;
	width: 100%;
	position: relative;
	padding-bottom: 10px;
}


.level .MuiCardHeader-title{
	font-weight: 600;
}

.level .MuiCardHeader-content{
	letter-spacing: 0.75px;
}

.level .price strong{
	font-size: 24px;
	font-weight: 800;
}

.level .price span{
	padding-left: 10px;
	padding-left: 10px;
    font-weight: 600;
    color: rgba(0,0,0,0.75);
    font-size: 13px !important;
}

.level .booking button{
	color:white;
	transition: background-color 1s;
	background-color: rgba(0, 0, 0, 0.75);
}

.level .booking button:hover{
	border-color: white;
    background-color: rgba(0, 0, 0, 0.5) !important;
}

@media screen and (max-width: 650px) {
 
	.levels .level:hover{
	  -webkit-transition: all 300ms ease-in;
	  -webkit-transform: scale(1);
	  -moz-transition: all 300ms ease-in;
	  -moz-transform: scale(1);
	  -ms-transition: all 300ms ease-in;
	  -ms-transform: scale(1);
	  transition: all 300ms ease-in;
	  transform: scale(1);
	  z-index: 5;
	}
}
