.contact-us{
	min-height: 30vh !important;
}

.contact-us .app-title{
	margin-bottom: 40px;
}

.contact-us .info-content{
	padding-bottom: 30px;
	text-align: center;
}

.contact-us .info-content .app-subtitle{
	text-align: left !important;
	font-weight: 700;
	letter-spacing: 5px !important;
	margin-right: 10px;
}


#contact-us .contactus-container{
	padding: 60px 0 0;
}
#contact-us .contactus-content>div{
	width: 45%;
	padding: 20px;
}

#contact-us .contactus-content .contactus-image-side img{
	padding: 0 20px;
	width:100%;
	max-width: 400px;
}


@media screen and (max-width: 850px) {
	#contact-us .contactus-content>div{
		width: 100%;
	}
}

.social-media {
  text-decoration: none;
  padding: 1px;
  width:50px;
  height:50px;
  border-radius: 10px;
  margin-right: 10px;
  transform: scale(0.9);
}

.social-media:hover {
    opacity: 0.7;
}

.social-media svg{
	width: 50px;
    height: 50px;
}

.social-media.fb {
  position: relative;
  background-color: #3B5998;
  display:flex;
  justify-content: center;
  align-items: center;
}

.fb .fb-container{
	width: 35px;
	height: 35px;
	background-color:white;
}

.fb svg{
  	z-index: 1;
	position: absolute;
	width: 100%;
	height: 100%;
	color: #3B5998;
}


.social-media.insta {
  color: white;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}