.menu-icon{
	color: white;
}

.full-menu-container{
	position:relative;
}

.menu-screen{
	position: absolute;
	width: calc(100vw + 48px);
	max-height: 0vh;
	height:calc(100vh + 60px);
	top: -60px;
	left: calc(-100vw + 48px);
	z-index: 1001;
	overflow-y:auto;
	-webkit-transition: .2s ease-in-out;   
    -webkit-transition-delay: .2s; 
    -moz-transition: .2s ease-in-out;   
    -moz-transition-delay: .2s; 
    -ms-transition: .2s ease-in-out;   
    -ms-transition-delay: .2s; 
    -o-transition: .2s ease-in-out;   
    -o-transition-delay: .2s; 
    transition: .2s ease-in-out;   
    transition-delay: .2s; 
  	background-color: #282c34;

}


.full-menu .menu-open{
	max-height: calc(100vh + 60px);
}

.full-menu .menu-item{
	padding: 10px 0;
	margin: 10px 0;
}

.white-button-icon{
	color: white !important;

}

media screen and (max-width: 600px) {
  width: calc(100vw + 40px);
	left: calc(-100vw + 40px);
}
