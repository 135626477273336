.grades{
}

.grades .grade-button{
	margin: 0 10px;
	width: 150px;
	transition: background-color .5s;
}

.grades .grade-button-container:hover .grade-button{
	color:white;
	border-color: white;
    background-color: rgba(0, 0, 0, 0.5) !important;
}
.grades .grade-button-active{
	color:white;
	border-color: white;
	background-color: rgba(0, 0, 0, 0.75);
}


.grades .grade-select-container{
	margin: 50px 0 30px;
}

.tabpanel{
	background-color: transparent;
}


.grades .grade-button-container{
	position: relative;
	padding: 5px;
}


.grades .grade-button-container .grade-icon{
	border-radius: 50%;
    border: 1px solid black;
	position: absolute;
	width: 40px;
	height:40px;
	z-index: 1;
	opacity: 1;
	transition: all .5s;
}

.grades .grade-button-container:hover .grade-icon, .grades .grade-button-container .grade-icon-active{
	opacity: 1;
}


.grades .grade-button-container .grade-icon-image{
	object-fit: contain;
	width: 100%;
}
