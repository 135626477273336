@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Fauna+One);
body {
  margin: 0;
  font-family: 'Fauna One', Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.row-flex{
	display:flex;
	width: 100%;
	flex-wrap: wrap;
	flex-direction: row;
}

.align-center{
	align-items: center;
}

.no-wrap{
	flex-wrap: nowrap !important;
}

.column-flex{
	display: flex;
	flex-direction: column;
}

.center-flex{
	justify-content: center;
	align-items: center;
}

.free-space{
	flex-basis:auto;
	flex-grow: 2;
}


.app-title{
	line-height: 100px;
	font-size: 60px;
	font-weight: 400;
  text-rendering: optimizeLegibility;
}

.app-subtitle{
    line-height: 1.333;
    text-rendering: optimizeLegibility;
    letter-spacing: 10px !important;
    color: rgba(0,0,0,0.8);
}

.app-subheader{
    line-height: 1.333;
    text-rendering: optimizeLegibility;
    letter-spacing: 1px !important;
    color: rgba(0,0,0,0.8);
    font-size: 12px;
}

.app-description{
	text-align: justify;
	line-height: 28px;
  color: rgba(0,0,0,0.8);
  font-size: 13px;
  font-weight: 550;
}


.background-cover{
	position: relative;
    background-size: cover;
}

.app-card{
	padding: 20px;
	border: solid 1px rgba(255,255,255,0.5);
	border-radius: 0px;
	background-color: transparent;
 transition: border-color, border-radius 1s;
}


.app-card:hover{
	border-radius: 30px;
}

.app-button{
	border-radius: 12px !important;
}

.background-cover::before {    
      content: "";
      position: absolute;
      background-color: black;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.5;
}

.flip-card {
  background-color: transparent;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
}

.flip-card-active{
	transform: rotateY(180deg);
}

/*.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}*/

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
}

.flip-card-back {
  transform: rotateY(180deg);
}

 .page-content{
  position: relative;
  width: 70vw;
  margin: 0 auto;
}

 .page-padding{
  padding: 64px;
  box-sizing: border-box;
}

figure{
  margin: 0px !important;
}

.app-scroll::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.app-scroll::-webkit-scrollbar
{
  width: 12px;
  background-color: #F5F5F5;
}

.app-scroll::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}

.MuiTooltip-tooltip{
    font-family: 'Fauna One', Arial, sans-serif !important;
}

.app-footer{
  min-height: 30vh;
}


.swiper-wrapper {
  padding-bottom: 20px;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
  bottom: -2px;
}

.swiper-button-prev, .swiper-button-next{
  color: #6a6a6a !important;
  fill: #6a6a6a;
}

.hide-swiper-button-next, .hide-swiper-button-prev{
    opacity: 0;
}



.react-tel-input .form-control{
  height: 56px;
  border-radius: 4px !important;
  width: 100% !important;
}

.react-tel-input:focus-within .special-label{
  color: #3f51b5;
}

.react-tel-input .form-control:focus {
    border-color: #3f51b5 !important;
    box-shadow: 0 0 0 1px #3f51b5 !important;
}

.error-MuiTextField-root .form-control{
    border-color: #f44336 !important;
}

.error-MuiTextField-root .special-label{
  color: #f44336 !important;
}


.error-MuiTextField-root .form-control:focus {
    border-color: #f44336 !important;
    box-shadow: 0 0 0 1px #f44336 !important;
}

.react-tel-input .special-label{
    color: rgba(0, 0, 0, 0.54);
    left: 5px !important;
}


.react-tel-input .form-control:focus ~ .special-label{
    color: #f44336;
}


.phoneinput-error{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin: 0 14px;
  color:#f44336;
  font-size: 0.75rem;
  line-height: 1.66;
}

.awssld__bullets button{
  width: 8px !important;
  height: 8px !important;
}
.menu-button{
	color: white !important;
}

.menu-button:hover{
	border-bottom: 1px white !important;
	border-bottom-width: 50%;
}

.inline-menu .menu-item{
	margin: 0 10px;
}

.inline-menu .menu-item .menu-item-title{
    font-size: 12px !important;
}

@keyframes wave{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
.menu-icon{
	color: white;
}

.full-menu-container{
	position:relative;
}

.menu-screen{
	position: absolute;
	width: calc(100vw + 48px);
	max-height: 0vh;
	height:calc(100vh + 60px);
	top: -60px;
	left: calc(-100vw + 48px);
	z-index: 1001;
	overflow-y:auto;
	-webkit-transition: .2s ease-in-out;   
    -webkit-transition-delay: .2s; 
    -moz-transition: .2s ease-in-out;   
    -moz-transition-delay: .2s; 
    -ms-transition: .2s ease-in-out;   
    -ms-transition-delay: .2s; 
    -o-transition: .2s ease-in-out;   
    -o-transition-delay: .2s; 
    transition: .2s ease-in-out;   
    transition-delay: .2s; 
  	background-color: #282c34;

}


.full-menu .menu-open{
	max-height: calc(100vh + 60px);
}

.full-menu .menu-item{
	padding: 10px 0;
	margin: 10px 0;
}

.white-button-icon{
	color: white !important;

}

media screen and (max-width: 600px) {
  width: calc(100vw + 40px);
	left: calc(-100vw + 40px);
}

#introduction{
	padding-top: 0px !important;
}

#introduction .app-title, #introduction .app-subtitle{
	color: white !important;
	z-index: 1;
}

#introduction #auto-type{
  display:flex;
  justify-content: center;
}

#introduction #intro-description{
    line-height: 1.333;
    text-rendering: optimizeLegibility;
    letter-spacing: 5px !important;
}



#introduction .booking-button{
	margin-top: 100px;
}

@media screen and (max-width: 850px) {
   #introduction #intro-description{
      font-size: 13px;
    }

  
}
.information .app-title{
	line-height: 100px;
	font-size: 60px;
	font-weight: 400;
	letter-spacing: 2px;
    text-rendering: optimizeLegibility;
}

.information .app-subtitle{
    line-height: 1.333;
    text-rendering: optimizeLegibility;
    font-weight: 600;
    letter-spacing: 5px !important;
   color: rgba(0,0,0,0.8);
}


.information .app-description{
	line-height: 28px;
    color: rgba(0,0,0,0.8);
    font-size: 13px;
    font-weight: 550;
}


.information .page-content{
	position: relative;
	width: 70vw;
    margin: 0 auto;
}

.information .page-padding{
	padding: 64px;
	box-sizing: border-box;
}

@media screen and (max-width: 1400px) {
   .information .page-content{
		width: 80vw;
	}

	.information .page-padding{
		padding: 40px;
	}	
}

@media screen and (max-width: 1000px) {
   .information .page-content{
		width: 90vw;
	}

	.information .page-padding{
		padding: 40px;
	}	
}

@media screen and (max-width: 850px) {
   .information .page-content{
		width: 95vw;
	}

	.information .page-padding{
		padding: 20px;
	}	

  	.information .app-title{
		font-size: 30px !important;
	}	
}

.image-container6 {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.85);
  background-size: cover !important;
}
.image-container6:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.image-container6 .image-layout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}
.image-container6 .image-layout span {
  width: 25%;
  height: 100%;
  transition: 0.5s;
  background-size: cover !important;
}
.image-container6 .image-layout span:nth-child(1) {
  background-position: 0;
  transition-delay: 0;
}
.image-container6 .image-layout span:nth-child(2) {
  background-position: 33.33333%;
  transition-delay: 0.1s;
}
.image-container6 .image-layout span:nth-child(3) {
  background-position: 66.66666%;
  transition-delay: 0.2s;
}
.image-container6 .image-layout span:nth-child(4) {
  background-position: 100%;
  transition-delay: 0.3s;
}

.image-container6:hover .image-layout > span, .image-container6.hover .image-layout > span {
  transform: translateY(-100%);
}
.image-container6:hover .content-layout, .image-container6.hover .content-layout  {
  transform: translateY(0%);
  transition: 1s;
  transition-delay: 0.1s;
}

.image-container6 .content-layout {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  padding: 20px 20px;
  width: 100%;
  height: 100%;
  transform: translateY(100%);
}
.projects-container{
	min-height: 0 !important;
	min-height: initial !important;
}

.projects-container .projects{
	margin: 40px 0;
}

.projects-container .page-padding{
	padding: 40px 0 !important;
}

.projects-container .project{
	position: relative;
}


.projects-container>.center-flex{
	align-items: flex-start;
}

.project-dialog .MuiDialog-paper{
	width: calc(80vh * 4/3);
	height: calc(80vh + 20px);
	max-width: 100% !important;
}

.project-dialog{
	width: 100%;
	height: 100%;
}

.projects .project{
	margin: 10px 0;
    width: 20%;
    height:250px;
    background-size: cover !important;

}

.project .play-button{
	padding: 0 !important;
}

.projects .project:hover .project-container, .projects .hover .project-container{
	opacity: 1;
}

.project .project-container{
	position: absolute;
	width: 100%;
	height: 100%;
    opacity: 0;
    transition: all 0.5s;
}

.project-container .play-button svg{
	font-size: 80px;
	color: rgba(250,250,250, 0.5);
}
.project-container .play-button:hover svg{
	color: rgba(250,250,250);
}

.projects .content-layout .app-subtitle{
	letter-spacing: 2px !important;
}


 .grade-button{
	margin: 0 10px;
	width: 150px;
	transition: background-color 1s;
}

 .grade-button:hover{
	color:white;
	border-color: white;
    background-color: rgba(0, 0, 0, 0.5) !important;
}
 .grade-button-active{
	color:white;
	border-color: white;
	background-color: rgba(0, 0, 0, 0.75);
}


@media screen and (max-width: 1200px) {
   .projects .project{
		width: 30%;
	}
}

@media screen and (max-width: 850px) {
   .projects .project{
		width: 45%;
	}
}

@media screen and (max-width: 800px) {
   .project-dialog .MuiDialog-paper{
		width: 90vw;
		height: calc( 90vw * 3/4 + 20px);
	}	
}

@media screen and (max-width: 650px) {
   .projects .project{
		width: 90%;
		height: 300px;
	}
}

.image-container5
{
  display: inline-block;
  position: relative;
  margin-top: 0px;
}
.image-container5 .retract
{
  height:200%;
  width:100%;
  -webkit-transition: all 500ms ease-in;
  -webkit-transform-origin: 50% 20%; 
  -webkit-transform: scale(1);
  -moz-transition: all 500ms ease-in;
  -moz-transform-origin: 50% 20%;
  -moz-transform: scale(1);
  -ms-transition: all 500ms ease-in;
  -ms-transform-origin: 50% 20%;
  -ms-transform: scale(1);
  transition: all 500ms ease-in;
  transform-origin: 50% 20%;
  transform: scale(1);
  position: absolute;
  z-index: 2;
  left:0;
}
.image-container5:hover .retract,
.image-container5.hover .retract
{
  -webkit-transition: all 500ms ease-in;
  -webkit-transform: scale(0.5);
  -moz-transition: all 500ms ease-in;
  -moz-transform: scale(0.5);
  -ms-transition: all 500ms ease-in;
  -ms-transform: scale(0.5);
  transition: all 500ms ease-in;
  transform: scale(0.5);
}
.image-container5 .acced
{
  position:absolute;
  z-index: 1; 
  top: 20%;
  text-align: left;
  -webkit-transition: all 500ms ease-in;
  -webkit-transform: scale(0);
  -moz-transition: all 500ms ease-in;
  -moz-transform: scale(0);
  -ms-transition: all 500ms ease-in;
  -ms-transform: scale(0);
  transition: all 500ms ease-in;
  transform: scale(0);
}

.image-container5:hover .acced, .image-container5.hover .acced
{
  -webkit-transition: all 500ms ease-in;
  -webkit-transform: scale(1);
  -moz-transition: all 500ms ease-in;
  -moz-transform: scale(1);
  -ms-transition: all 500ms ease-in;
  -ms-transform: scale(1);
  transition: all 500ms ease-in;
  transform: scale(1);
}

.image-container5 .retract>img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.image-container5 .layout{
  padding: 15px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.image-container5 .image-layout, .image-container5 .content-layout{
  height: 50%;
  position: relative;
}

.image-container5 .content-layout{
  padding: 20px;
}
.categories{
	color: white;
	justify-content: center;
}

.categories *{
	box-sizing: border-box;
}

.courses-categories .page-content{
	width: 100vw;
}

.courses-categories .page-padding{
	padding: 40px 0;
}

.categories .category{
	border-radius: 10px;
	flex: 1 1;
	margin: 20px 10px 20px;
	height: calc(280px + (50 - 18)*(100vw - 400px)/600);
	max-width: 600px;
	box-shadow: -2px 2px 16px -1px rgba(0,0,0,0.65);
}

.categories .icon-container{
	height: 50%;
}

.categories .app-card{
	background-color: rgba(0, 0, 0, 0.3);
	height: 300px;
}

.categories button{
	color: #007aff;
	text-transform: capitalize !important;
}


@media screen and (max-width: 1000px) {
	.categories .category{
		flex: 100% 1;
	}

	.categories .app-card{
		height: 350px;
	}

}

@media screen and (max-width: 450px) {
 
	.categories .category{
		margin: 20px 10px 20px;
		height: 60vh;
	}
}

.levels{
	align-items: flex-start;
}

/*.courses-grades .page-padding{
	padding: 20px !important;
}*/

.courses-grades .page-content{
	width: 100% !important;
}

.levels *{
	box-sizing: border-box;
}

.levels .level-content{
	padding: 20px !important;
	overflow-y: auto;
}

.levels .level-title-container{
	padding:20px;
	min-height: 150px;
}

.levels .level-content .app-description{
	letter-spacing: 0.5px;
	font-size: 13px;
	line-height: 20px;
}

.levels .level-content ul{  
	font-size: 13px;
	letter-spacing: 0.5px;
	font-weight: 600;
}



.levels .swiper-slide{
	padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
}

.level-container{
	flex: 25% 1;
}

.levels .level{
	height: 100%;
	 -webkit-transition: all 300ms ease-in;
	  -webkit-transform: scale(1);
	  -moz-transition: all 300ms ease-in;
	  -moz-transform: scale(1);
	  -ms-transition: all 300ms ease-in;
	  -ms-transform: scale(1);
	  transition: all 300ms ease-in;
	  transform: scale(1);
}


.levels .level:hover{
  -webkit-transition: all 300ms ease-in;
  -webkit-transform: scale(1.02);
  -moz-transition: all 300ms ease-in;
  -moz-transform: scale(1.02);
  -ms-transition: all 300ms ease-in;
  -ms-transform: scale(1.02);
  transition: all 300ms ease-in;
  transform: scale(1.02);
  z-index: 5;
}

.level .MuiListItem-root{
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.level .MuiListItemIcon-root{
	min-width: 45px;
}

.level .title{
	padding-left: 20px;
}

.level-media{
	height:225px;
	width: 100%;
	position: relative;
	padding-bottom: 10px;
}


.level .MuiCardHeader-title{
	font-weight: 600;
}

.level .MuiCardHeader-content{
	letter-spacing: 0.75px;
}

.level .price strong{
	font-size: 24px;
	font-weight: 800;
}

.level .price span{
	padding-left: 10px;
	padding-left: 10px;
    font-weight: 600;
    color: rgba(0,0,0,0.75);
    font-size: 13px !important;
}

.level .booking button{
	color:white;
	transition: background-color 1s;
	background-color: rgba(0, 0, 0, 0.75);
}

.level .booking button:hover{
	border-color: white;
    background-color: rgba(0, 0, 0, 0.5) !important;
}

@media screen and (max-width: 650px) {
 
	.levels .level:hover{
	  -webkit-transition: all 300ms ease-in;
	  -webkit-transform: scale(1);
	  -moz-transition: all 300ms ease-in;
	  -moz-transform: scale(1);
	  -ms-transition: all 300ms ease-in;
	  -ms-transform: scale(1);
	  transition: all 300ms ease-in;
	  transform: scale(1);
	  z-index: 5;
	}
}

.grades{
}

.grades .grade-button{
	margin: 0 10px;
	width: 150px;
	transition: background-color .5s;
}

.grades .grade-button-container:hover .grade-button{
	color:white;
	border-color: white;
    background-color: rgba(0, 0, 0, 0.5) !important;
}
.grades .grade-button-active{
	color:white;
	border-color: white;
	background-color: rgba(0, 0, 0, 0.75);
}


.grades .grade-select-container{
	margin: 50px 0 30px;
}

.tabpanel{
	background-color: transparent;
}


.grades .grade-button-container{
	position: relative;
	padding: 5px;
}


.grades .grade-button-container .grade-icon{
	border-radius: 50%;
    border: 1px solid black;
	position: absolute;
	width: 40px;
	height:40px;
	z-index: 1;
	opacity: 1;
	transition: all .5s;
}

.grades .grade-button-container:hover .grade-icon, .grades .grade-button-container .grade-icon-active{
	opacity: 1;
}


.grades .grade-button-container .grade-icon-image{
	object-fit: contain;
	width: 100%;
}

#courses{
	min-height: 100vh;
}

#courses *{
	box-sizing: border-box;

}

#courses >.center-flex{
	align-items: flex-start;
}

#company *{
	box-sizing: border-box;
}

#company{
	min-height: 50vh;
}

.company-container{
	padding: 20px 0;
}

.company-image-side{
	width:40%;
}

 .company-image-side img{
	max-width: 500px;
}

 .company-content-side{
	width: 60%;
}

.company-content .company-image-side img{
	padding: 0 30px;
	width:100%;
}

#company .app-description{
	letter-spacing: 2px;
}



.whatwedo .app-description{
	letter-spacing: 2px;
}

@media screen and (max-width: 650px) {
     .company-image-side{
		width:100%;
	}

	 .company-content-side{
		width: 100%;
	}	
}
.form-container .MuiTextField-root{
	height: 85px;
}

.form-container .Mui-error{
	margin-top: 0px;
}

.image-container{
	object-fit: contain;
}


.image-container img{
	width: 80% !important;
}
#booking{
	position: relative;
	background-color: transparent;
}

#booking .booking-header{
	height:40px;
}

#booking iframe{
	border: none !important;
	width: 100%;
	height:80vh;
}


#booking .app-description{
    font-family: montserrat-medium,sans-serif;
    line-height: 1.333;
    text-rendering: optimizeLegibility;
    letter-spacing: 5px !important;
}

#hiring{
	position: relative;
	background-color: transparent;
}

#hiring .hiring-header{
	height:40px;
}

#hiring iframe{
	border: none !important;
	width: 100%;
	height:80vh;
}


.hiring-dialog .MuiDialog-paper{
    width:90vw;
    height:90vh;
	max-width: 90vw;
}   

#hiring .app-description{
    font-family: montserrat-medium,sans-serif;
    line-height: 1.333;
    text-rendering: optimizeLegibility;
    letter-spacing: 5px !important;
}

.members{
	padding: 40px 0 20px;
	max-width: 1200px;
	margin:auto;
	display:flex;
	justify-content: center;
	align-items: center;
}

.members .app-description{
	letter-spacing: 1px !important;
}

.member{
	width: 245px;
	padding-bottom: 20px;
}

.member .MuiAvatar-root{
	width: 200px;
	height: 200px;
}

.member .MuiAvatar-root img{
	height: 100%;
	width:100%;
	object-fit: cover;
	-webkit-user-select:none;
    -webkit-touch-callout:none;
}

.MuiTooltip-arrow{
	color: #f5f5f9 !important;
}

.MuiTooltip-tooltip .app-subtitle{
	font-size: 16px;
	text-rendering: optimizeLegibility;
    font-weight: 600;
    letter-spacing: 5px !important;
    font-family: 'Fauna One', Arial, sans-serif;
}

.MuiTooltip-arrow::before{
	border: 1px solid rgba(0,0,0,0.3);
}

.member .MuiAvatar-root{
	border: solid 2px;
	border-color: white;
}

.member .MuiAvatar-root:hover{
	border-color: rgba(0,0,0,0.5);
}
.contact-us{
	min-height: 30vh !important;
}

.contact-us .app-title{
	margin-bottom: 40px;
}

.contact-us .info-content{
	padding-bottom: 30px;
	text-align: center;
}

.contact-us .info-content .app-subtitle{
	text-align: left !important;
	font-weight: 700;
	letter-spacing: 5px !important;
	margin-right: 10px;
}


#contact-us .contactus-container{
	padding: 60px 0 0;
}
#contact-us .contactus-content>div{
	width: 45%;
	padding: 20px;
}

#contact-us .contactus-content .contactus-image-side img{
	padding: 0 20px;
	width:100%;
	max-width: 400px;
}


@media screen and (max-width: 850px) {
	#contact-us .contactus-content>div{
		width: 100%;
	}
}

.social-media {
  text-decoration: none;
  padding: 1px;
  width:50px;
  height:50px;
  border-radius: 10px;
  margin-right: 10px;
  transform: scale(0.9);
}

.social-media:hover {
    opacity: 0.7;
}

.social-media svg{
	width: 50px;
    height: 50px;
}

.social-media.fb {
  position: relative;
  background-color: #3B5998;
  display:flex;
  justify-content: center;
  align-items: center;
}

.fb .fb-container{
	width: 35px;
	height: 35px;
	background-color:white;
}

.fb svg{
  	z-index: 1;
	position: absolute;
	width: 100%;
	height: 100%;
	color: #3B5998;
}


.social-media.insta {
  color: white;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}
#team-member{
	min-height: 30vh;
}

#team-member .page-content{
	width: 100vw;
}


#about-us{
	min-height:30vh;
}

.about-us .page-padding, .team-member .page-padding, .contact-us .page-padding{
	padding: 20px 0 0;
}

.about-us> .page-padding{
	padding-top: 64px;
}
#team-member .app-description{
	letter-spacing: 1px;
	font-weight: 500;
}

#testimonials{
	min-height: 100vh;
}

@media screen and (max-width: 850px) {
   #testimonials.information .page-content{
		width: 100%;
	}	
}
figure.image-container2 {
  position: relative;
  overflow: hidden;
  margin: 10px;
  width: 100%;
  text-align: center;
  color: white;
}
figure.image-container2 * {
  box-sizing: border-box;
}
figure.image-container2 img {
  opacity: 1;
  width: 100%;
  transition: opacity 0.35s;
  max-height: 100vh;
  object-fit: cover;

}
figure.image-container2 figcaption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: left;
}


figure.image-container2 figcaption > div {
  float: left;
  height: 100%;
  overflow: hidden;
  position: relative;
}
figure.image-container2 figcaption::before {
  position: absolute;
  top: 50%;
  bottom: 50%;
  width: 2px;
  content: '';
  opacity: 0;
  background-color: rgba(255, 255, 255, 0);
  transition: all 0.4s;
  transition-delay: 0.6s;
}
figure.image-container2 .left-page,
figure.image-container2 .right-page {
  height: 100%;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  transition: opacity 0.45s,transform 0.45s;
}
figure.image-container2 .left-page {
  transform: translate3d(50%, 0%, 0);
  transition-delay: 0s;
}


figure.image-container2 .right-page {
  transform: translate3d(-50%, 0%, 0);
  transition-delay: 0s;
}

figure.image-container2 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  color: #ffffff;
}

figure.image-container2.hover img, figure.image-container2:hover img {
  opacity: 0.7;
}

figure.image-container2.hover figcaption .left-page,
figure.image-container2.hover figcaption .right-page,
figure.image-container2:hover figcaption .left-page,
figure.image-container2:hover figcaption .right-page {
  transform: translate3d(0%, 0%, 0);
  transition-delay: 0.3s;
}


figure.image-container2.hover figcaption .left-page,
figure.image-container2.hover figcaption .right-page,
figure.image-container2:hover figcaption .left-page,
figure.image-container2:hover figcaption .right-page {
  opacity: 1;
}

figure.image-container2.hover figcaption::before,
figure.image-container2:hover figcaption::before {
  background: #ffffff;
  top: 0px;
  bottom: 0px;
  opacity: 1;
  transition-delay: 0s;
}

.feedback-container{
	margin: 20px 0 40px;
}


.feedback-container *{
	box-sizing: border-box;
}

.feedback-container .parents, .feedback-container .students{
	box-shadow: -2px 2px 16px -1px rgba(0,0,0,0.1);
}

.feedback-container .left-container .page-padding{
	padding: 20px;
}

.feedback-container .information .page-padding{
	max-height: 400px !important;
	padding:  40px 10px !important;
}


.feedback-container .left-container .app-title{
	line-height: 1.333;
    text-rendering: optimizeLegibility;
    letter-spacing: 10px !important;
    text-align: right !important;
    font-size: 16px;
    font-weight: 500;
}

.feedback-container>figure{
	min-height: 400px;
}

.feedback-container .feedbacks{
	padding: 20px;
}

.feedback-container, .feedback-container .MuiListItemText-root>span{
	color: black;
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 1px;
}


.feedback-container .right-container .page-padding{
	padding: 20px 10px;
}

.feedback-container .right-container .page-padding>div{
	height: 100%;
	overflow-y: auto;
}

.feedback-container .right-container .page-padding>div::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: transparent;
}

.feedback-container .right-container .page-padding>div::-webkit-scrollbar{
	width: 6px;
}

.feedback-container .right-container .page-padding>div::-webkit-scrollbar-thumb{
	background-color: white;
}

.feedback-container .MuiAvatar-root{
	background-color: rgba(0, 0, 0, 0.5);
	left: -15px;
	width: 100px;
	height: 100px;
}

.feedback-container .MuiAvatar-root img{
	opacity: 1 !important;
	height: 100%;
}
.feedback-container3{
  margin-bottom: 50px;
}

.feedback-container3 *{
  box-sizing: border-box;
}

.feedback-container3 .page-content{
  height:400px;
}

.feedback-container3 .page-content .awssld{
  height: 100%;
}


.feedback-container3 .information .page-padding{
  padding-left:20px ;
  padding-right:20px ;
  padding-top: 40px !important;
  padding-bottom: 50px !important;
}

.feedback3-slide-container{
  position: relative;
  padding: 0 50px;
}

.feedback3-slide-container>div{
}

.feedback3-slide-container .image-container{
  width: 40%;
  margin: 30px 0 30px 35px;
  max-height: 300px;
}

.feedback3-slide-container .image-container img{
  width: 100%;
  height:100%;
  object-fit: cover;  
}

.feedback-container3 .awssld__content{
  background-color:  transparent !important;
}
.feedback3-slide-container .feedback3-content{
  border: 5px solid white;
  margin: 30px 35px;
  padding: 15px 40px;
  max-height: 300px;
  overflow-y: auto;
}


.feedback-container3 .awssld__bullets{
  bottom: -35px;
  z-index: 2;
}

.feedback-container3 .app-subtitle{
  padding: 10px 0;
}


@media screen and (max-width: 850px) {
  

  .feedback-container3 .page-content{
    height:80vh;
  }

  .feedback-container3 .image-container{
    width: 100%;
    margin: 0;
  }

  .feedback-container3 .image-container img{
    width: 30vw;
  }

  .feedback3-slide-container .feedback3-content{
    border: none;
    margin: 10px 0px;
    align-items: flex-start;
    padding: 20px;
  }

  .feedback3-slide-container .feedback3-content .app-subtitle, .feedback3-slide-container .feedback3-content .app-subheader{
     text-align: center;
  }
}

.feedback3-content .app-description::before, .feedback3-content .app-description::after{
  content: '"';
}

 .quoteIcon{
  position: absolute;
  z-index: -1 ;
  color: rgba(250, 250, 250, 0.5);
  font-size: 200px;
}

.openQuoteIcon{
  top: 40px;
  right: 80px;
}

.closeQuoteIcon{
  bottom: -15px;
  right: -5px;
}

@media screen and (max-width: 500px) {
  
  .feedback3-slide-container{
    padding: 0px;
  }

  .feedback-container3 .awssld__bullets{
    bottom: -30px;
  }

  .feedback3-slide-container .feedback3-content{
    padding: 10px 15px;
    margin: 10px 0;
    position: relative;
  }
}

@media screen and (max-height: 400px) {
    .feedback-container3 .page-content{
        height:550px;
      }

}

.home >div{
	min-height:100vh;
	box-sizing: border-box;
	position: relative;
	overflow-x:hidden;
}

.home .introduction{
	padding-top: 64px;
	background-image: url(/static/media/introduction.e94fdade.jpg);
}
.App{
  background-color: transparent !important;
}

.app-toolbar {
  background-color:  rgb(0 0 0 / 50%) !important
}


.inline{
  display: block;
}

.fullscreen{
  display:none;
}

.menu-item{
  position: relative;
  cursor: pointer;
  text-decoration: none;
}


.menu-item .menu-item-title{
  color: white;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 3px;
  line-height: 10px;
  margin-bottom: 10px;
  font-weight: 600;
}

.menu-item .menu-item-underline{
  position: absolute; 
  bottom: -5px; 
  left: 0; 
  width: 0; 
  height: 1.5px; 
  background-color: white; 
  transition: 0.2s;
}  

.menu-item:hover .menu-item-underline{
  width: 50%;
}

.active-page .menu-item-underline{
  width: 50%;
}

.logo{
  color: white !important;
  text-decoration: none;
  text-transform: none !important;
  text-transform: initial !important;
  font-size: 24px;
  font-weight: 700;
  padding-right:20px;
}

.logo .logo-image{
    height: 40px;
}

.logo img{
  object-fit: fill;
  height: 100% !important;
}

.app-toolbar{
  transition: all 2s ease-in-out;
}

.transparent-toolbar{
  background-color: transparent !important;
  box-shadow: none !important;
}

.app-toolbar .menu-icon{
  background-color: transparent !important;
}

.booking-button{
    color: white !important;
    border-color: rgba(250, 250, 250, 0.3) !important;
    width: 200px;
    background-color: rgba(0, 0, 0, 0.75) !important;
}

.booking-button:hover{
    background-color: rgba(0, 0, 0, 0.25) !important;
}

.booking-dialog .MuiDialog-paper{
  width:95vw;
  height:95vh;
  max-width: 100% !important;
  max-height: 100% !important;
}


.app-footer .footer-container{
  flex: 20% 1;
  margin-right: 20px;
}

.app-footer .app-subtitle{
  color: #bbc0c4;
}

.app-footer .footer-content a{
  text-decoration: none;
  color: #848d95;
  font-size: 13px;
}

.app-footer .footer-content a:hover{
  opacity: 0.5;
}

@media screen and (max-width: 970px) {
  .inline{
    display: none;
  }
  .logo-title{
    display: none;
  }

  .fullscreen{
    display:block;
  }
}

@media screen and (max-width: 650px) {
  .booking-dialog .MuiDialog-paper{
    width:100vw !important;
    height:100vh !important;
    margin: 0px !important;
  }
}
