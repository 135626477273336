.home >div{
	min-height:100vh;
	box-sizing: border-box;
	position: relative;
	overflow-x:hidden;
}

.home .introduction{
	padding-top: 64px;
	background-image: url("./../assets/introduction.jpg");
}