#courses{
	min-height: 100vh;
}

#courses *{
	box-sizing: border-box;

}

#courses >.center-flex{
	align-items: flex-start;
}