#introduction{
	padding-top: 0px !important;
}

#introduction .app-title, #introduction .app-subtitle{
	color: white !important;
	z-index: 1;
}

#introduction #auto-type{
  display:flex;
  justify-content: center;
}

#introduction #intro-description{
    line-height: 1.333;
    text-rendering: optimizeLegibility;
    letter-spacing: 5px !important;
}



#introduction .booking-button{
	margin-top: 100px;
}

@media screen and (max-width: 850px) {
   #introduction #intro-description{
      font-size: 13px;
    }

  
}