.App{
  background-color: transparent !important;
}

.app-toolbar {
  background-color:  rgb(0 0 0 / 50%) !important
}


.inline{
  display: block;
}

.fullscreen{
  display:none;
}

.menu-item{
  position: relative;
  cursor: pointer;
  text-decoration: none;
}


.menu-item .menu-item-title{
  color: white;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 3px;
  line-height: 10px;
  margin-bottom: 10px;
  font-weight: 600;
}

.menu-item .menu-item-underline{
  position: absolute; 
  bottom: -5px; 
  left: 0; 
  width: 0; 
  height: 1.5px; 
  background-color: white; 
  transition: 0.2s;
}  

.menu-item:hover .menu-item-underline{
  width: 50%;
}

.active-page .menu-item-underline{
  width: 50%;
}

.logo{
  color: white !important;
  text-decoration: none;
  text-transform: initial !important;
  font-size: 24px;
  font-weight: 700;
  padding-right:20px;
}

.logo .logo-image{
    height: 40px;
}

.logo img{
  object-fit: fill;
  height: 100% !important;
}

.app-toolbar{
  transition: all 2s ease-in-out;
}

.transparent-toolbar{
  background-color: transparent !important;
  box-shadow: none !important;
}

.app-toolbar .menu-icon{
  background-color: transparent !important;
}

.booking-button{
    color: white !important;
    border-color: rgba(250, 250, 250, 0.3) !important;
    width: 200px;
    background-color: rgba(0, 0, 0, 0.75) !important;
}

.booking-button:hover{
    background-color: rgba(0, 0, 0, 0.25) !important;
}

.booking-dialog .MuiDialog-paper{
  width:95vw;
  height:95vh;
  max-width: 100% !important;
  max-height: 100% !important;
}


.app-footer .footer-container{
  flex: 20%;
  margin-right: 20px;
}

.app-footer .app-subtitle{
  color: #bbc0c4;
}

.app-footer .footer-content a{
  text-decoration: none;
  color: #848d95;
  font-size: 13px;
}

.app-footer .footer-content a:hover{
  opacity: 0.5;
}

@media screen and (max-width: 970px) {
  .inline{
    display: none;
  }
  .logo-title{
    display: none;
  }

  .fullscreen{
    display:block;
  }
}

@media screen and (max-width: 650px) {
  .booking-dialog .MuiDialog-paper{
    width:100vw !important;
    height:100vh !important;
    margin: 0px !important;
  }
}