.feedback-container{
	margin: 20px 0 40px;
}


.feedback-container *{
	box-sizing: border-box;
}

.feedback-container .parents, .feedback-container .students{
	-webkit-box-shadow: -2px 2px 16px -1px rgba(0,0,0,0.1);
	-moz-box-shadow: -2px 2px 16px -1px rgba(0,0,0,0.1);
	box-shadow: -2px 2px 16px -1px rgba(0,0,0,0.1);
}

.feedback-container .left-container .page-padding{
	padding: 20px;
}

.feedback-container .information .page-padding{
	max-height: 400px !important;
	padding:  40px 10px !important;
}


.feedback-container .left-container .app-title{
	line-height: 1.333;
    text-rendering: optimizeLegibility;
    letter-spacing: 10px !important;
    text-align: right !important;
    font-size: 16px;
    font-weight: 500;
}

.feedback-container>figure{
	min-height: 400px;
}

.feedback-container .feedbacks{
	padding: 20px;
}

.feedback-container, .feedback-container .MuiListItemText-root>span{
	color: black;
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 1px;
}


.feedback-container .right-container .page-padding{
	padding: 20px 10px;
}

.feedback-container .right-container .page-padding>div{
	height: 100%;
	overflow-y: auto;
}

.feedback-container .right-container .page-padding>div::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: transparent;
}

.feedback-container .right-container .page-padding>div::-webkit-scrollbar{
	width: 6px;
}

.feedback-container .right-container .page-padding>div::-webkit-scrollbar-thumb{
	background-color: white;
}

.feedback-container .MuiAvatar-root{
	background-color: rgba(0, 0, 0, 0.5);
	left: -15px;
	width: 100px;
	height: 100px;
}

.feedback-container .MuiAvatar-root img{
	opacity: 1 !important;
	height: 100%;
}