
.image-container5
{
  display: inline-block;
  position: relative;
  margin-top: 0px;
}
.image-container5 .retract
{
  height:200%;
  width:100%;
  -webkit-transition: all 500ms ease-in;
  -webkit-transform-origin: 50% 20%; 
  -webkit-transform: scale(1);
  -moz-transition: all 500ms ease-in;
  -moz-transform-origin: 50% 20%;
  -moz-transform: scale(1);
  -ms-transition: all 500ms ease-in;
  -ms-transform-origin: 50% 20%;
  -ms-transform: scale(1);
  transition: all 500ms ease-in;
  transform-origin: 50% 20%;
  transform: scale(1);
  position: absolute;
  z-index: 2;
  left:0;
}
.image-container5:hover .retract,
.image-container5.hover .retract
{
  -webkit-transition: all 500ms ease-in;
  -webkit-transform: scale(0.5);
  -moz-transition: all 500ms ease-in;
  -moz-transform: scale(0.5);
  -ms-transition: all 500ms ease-in;
  -ms-transform: scale(0.5);
  transition: all 500ms ease-in;
  transform: scale(0.5);
}
.image-container5 .acced
{
  position:absolute;
  z-index: 1; 
  top: 20%;
  text-align: left;
  -webkit-transition: all 500ms ease-in;
  -webkit-transform: scale(0);
  -moz-transition: all 500ms ease-in;
  -moz-transform: scale(0);
  -ms-transition: all 500ms ease-in;
  -ms-transform: scale(0);
  transition: all 500ms ease-in;
  transform: scale(0);
}

.image-container5:hover .acced, .image-container5.hover .acced
{
  -webkit-transition: all 500ms ease-in;
  -webkit-transform: scale(1);
  -moz-transition: all 500ms ease-in;
  -moz-transform: scale(1);
  -ms-transition: all 500ms ease-in;
  -ms-transform: scale(1);
  transition: all 500ms ease-in;
  transform: scale(1);
}

.image-container5 .retract>img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.image-container5 .layout{
  padding: 15px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.image-container5 .image-layout, .image-container5 .content-layout{
  height: 50%;
  position: relative;
}

.image-container5 .content-layout{
  padding: 20px;
}