.form-container .MuiTextField-root{
	height: 85px;
}

.form-container .Mui-error{
	margin-top: 0px;
}

.image-container{
	object-fit: contain;
}


.image-container img{
	width: 80% !important;
}