.feedback-container3{
  margin-bottom: 50px;
}

.feedback-container3 *{
  box-sizing: border-box;
}

.feedback-container3 .page-content{
  height:400px;
}

.feedback-container3 .page-content .awssld{
  height: 100%;
}


.feedback-container3 .information .page-padding{
  padding-left:20px ;
  padding-right:20px ;
  padding-top: 40px !important;
  padding-bottom: 50px !important;
}

.feedback3-slide-container{
  position: relative;
  padding: 0 50px;
}

.feedback3-slide-container>div{
}

.feedback3-slide-container .image-container{
  width: 40%;
  margin: 30px 0 30px 35px;
  max-height: 300px;
}

.feedback3-slide-container .image-container img{
  width: 100%;
  height:100%;
  object-fit: cover;  
}

.feedback-container3 .awssld__content{
  background-color:  transparent !important;
}
.feedback3-slide-container .feedback3-content{
  border: 5px solid white;
  margin: 30px 35px;
  padding: 15px 40px;
  max-height: 300px;
  overflow-y: auto;
}


.feedback-container3 .awssld__bullets{
  bottom: -35px;
  z-index: 2;
}

.feedback-container3 .app-subtitle{
  padding: 10px 0;
}


@media screen and (max-width: 850px) {
  

  .feedback-container3 .page-content{
    height:80vh;
  }

  .feedback-container3 .image-container{
    width: 100%;
    margin: 0;
  }

  .feedback-container3 .image-container img{
    width: 30vw;
  }

  .feedback3-slide-container .feedback3-content{
    border: none;
    margin: 10px 0px;
    align-items: flex-start;
    padding: 20px;
  }

  .feedback3-slide-container .feedback3-content .app-subtitle, .feedback3-slide-container .feedback3-content .app-subheader{
     text-align: center;
  }
}

.feedback3-content .app-description::before, .feedback3-content .app-description::after{
  content: '"';
}

 .quoteIcon{
  position: absolute;
  z-index: -1 ;
  color: rgba(250, 250, 250, 0.5);
  font-size: 200px;
}

.openQuoteIcon{
  top: 40px;
  right: 80px;
}

.closeQuoteIcon{
  bottom: -15px;
  right: -5px;
}

@media screen and (max-width: 500px) {
  
  .feedback3-slide-container{
    padding: 0px;
  }

  .feedback-container3 .awssld__bullets{
    bottom: -30px;
  }

  .feedback3-slide-container .feedback3-content{
    padding: 10px 15px;
    margin: 10px 0;
    position: relative;
  }
}

@media screen and (max-height: 400px) {
    .feedback-container3 .page-content{
        height:550px;
      }

}
