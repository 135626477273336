.information .app-title{
	line-height: 100px;
	font-size: 60px;
	font-weight: 400;
	letter-spacing: 2px;
    text-rendering: optimizeLegibility;
}

.information .app-subtitle{
    line-height: 1.333;
    text-rendering: optimizeLegibility;
    font-weight: 600;
    letter-spacing: 5px !important;
   color: rgba(0,0,0,0.8);
}


.information .app-description{
	line-height: 28px;
    color: rgba(0,0,0,0.8);
    font-size: 13px;
    font-weight: 550;
}


.information .page-content{
	position: relative;
	width: 70vw;
    margin: 0 auto;
}

.information .page-padding{
	padding: 64px;
	box-sizing: border-box;
}

@media screen and (max-width: 1400px) {
   .information .page-content{
		width: 80vw;
	}

	.information .page-padding{
		padding: 40px;
	}	
}

@media screen and (max-width: 1000px) {
   .information .page-content{
		width: 90vw;
	}

	.information .page-padding{
		padding: 40px;
	}	
}

@media screen and (max-width: 850px) {
   .information .page-content{
		width: 95vw;
	}

	.information .page-padding{
		padding: 20px;
	}	

  	.information .app-title{
		font-size: 30px !important;
	}	
}