.categories{
	color: white;
	justify-content: center;
}

.categories *{
	box-sizing: border-box;
}

.courses-categories .page-content{
	width: 100vw;
}

.courses-categories .page-padding{
	padding: 40px 0;
}

.categories .category{
	border-radius: 10px;
	flex: 1;
	margin: 20px 10px 20px;
	height: calc(280px + (50 - 18)*(100vw - 400px)/600);
	max-width: 600px;
	-webkit-box-shadow: -2px 2px 16px -1px rgba(0,0,0,0.65);
	-moz-box-shadow: -2px 2px 16px -1px rgba(0,0,0,0.65);
	box-shadow: -2px 2px 16px -1px rgba(0,0,0,0.65);
}

.categories .icon-container{
	height: 50%;
}

.categories .app-card{
	background-color: rgba(0, 0, 0, 0.3);
	height: 300px;
}

.categories button{
	color: #007aff;
	text-transform: capitalize !important;
}


@media screen and (max-width: 1000px) {
	.categories .category{
		flex: 100%;
	}

	.categories .app-card{
		height: 350px;
	}

}

@media screen and (max-width: 450px) {
 
	.categories .category{
		margin: 20px 10px 20px;
		height: 60vh;
	}
}
