#booking{
	position: relative;
	background-color: transparent;
}

#booking .booking-header{
	height:40px;
}

#booking iframe{
	border: none !important;
	width: 100%;
	height:80vh;
}


#booking .app-description{
    font-family: montserrat-medium,sans-serif;
    line-height: 1.333;
    text-rendering: optimizeLegibility;
    letter-spacing: 5px !important;
}
