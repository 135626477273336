.members{
	padding: 40px 0 20px;
	max-width: 1200px;
	margin:auto;
	display:flex;
	justify-content: center;
	align-items: center;
}

.members .app-description{
	letter-spacing: 1px !important;
}

.member{
	width: 245px;
	padding-bottom: 20px;
}

.member .MuiAvatar-root{
	width: 200px;
	height: 200px;
}

.member .MuiAvatar-root img{
	height: 100%;
	width:100%;
	object-fit: cover;
	-webkit-user-select:none;
    -webkit-touch-callout:none;
}

.MuiTooltip-arrow{
	color: #f5f5f9 !important;
}

.MuiTooltip-tooltip .app-subtitle{
	font-size: 16px;
	text-rendering: optimizeLegibility;
    font-weight: 600;
    letter-spacing: 5px !important;
    font-family: 'Fauna One', Arial, sans-serif;
}

.MuiTooltip-arrow::before{
	border: 1px solid rgba(0,0,0,0.3);
}

.member .MuiAvatar-root{
	border: solid 2px;
	border-color: white;
}

.member .MuiAvatar-root:hover{
	border-color: rgba(0,0,0,0.5);
}