.menu-button{
	color: white !important;
}

.menu-button:hover{
	border-bottom: 1px white !important;
	border-bottom-width: 50%;
}

.inline-menu .menu-item{
	margin: 0 10px;
}

.inline-menu .menu-item .menu-item-title{
    font-size: 12px !important;
}

@keyframes wave{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

@-webkit-keyframes wave{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}