figure.image-container2 {
  position: relative;
  overflow: hidden;
  margin: 10px;
  width: 100%;
  text-align: center;
  color: white;
}
figure.image-container2 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
figure.image-container2 img {
  opacity: 1;
  width: 100%;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
  max-height: 100vh;
  object-fit: cover;

}
figure.image-container2 figcaption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: left;
}


figure.image-container2 figcaption > div {
  float: left;
  height: 100%;
  overflow: hidden;
  position: relative;
}
figure.image-container2 figcaption::before {
  position: absolute;
  top: 50%;
  bottom: 50%;
  width: 2px;
  content: '';
  opacity: 0;
  background-color: rgba(255, 255, 255, 0);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
figure.image-container2 .left-page,
figure.image-container2 .right-page {
  height: 100%;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  -webkit-transition: opacity 0.45s, -webkit-transform 0.45s;
  transition: opacity 0.45s,-webkit-transform 0.45s,-moz-transform 0.45s,-o-transform 0.45s,transform 0.45s;
}
figure.image-container2 .left-page {
  -webkit-transform: translate3d(50%, 0%, 0);
  transform: translate3d(50%, 0%, 0);
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}


figure.image-container2 .right-page {
  -webkit-transform: translate3d(-50%, 0%, 0);
  transform: translate3d(-50%, 0%, 0);
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

figure.image-container2 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  color: #ffffff;
}

figure.image-container2.hover img, figure.image-container2:hover img {
  opacity: 0.7;
}

figure.image-container2.hover figcaption .left-page,
figure.image-container2.hover figcaption .right-page,
figure.image-container2:hover figcaption .left-page,
figure.image-container2:hover figcaption .right-page {
  -webkit-transform: translate3d(0%, 0%, 0);
  transform: translate3d(0%, 0%, 0);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}


figure.image-container2.hover figcaption .left-page,
figure.image-container2.hover figcaption .right-page,
figure.image-container2:hover figcaption .left-page,
figure.image-container2:hover figcaption .right-page {
  opacity: 1;
}

figure.image-container2.hover figcaption::before,
figure.image-container2:hover figcaption::before {
  background: #ffffff;
  top: 0px;
  bottom: 0px;
  opacity: 1;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
