
.image-container6 {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.85);
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}
.image-container6:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.image-container6 .image-layout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}
.image-container6 .image-layout span {
  width: 25%;
  height: 100%;
  transition: 0.5s;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}
.image-container6 .image-layout span:nth-child(1) {
  background-position: 0;
  transition-delay: 0;
}
.image-container6 .image-layout span:nth-child(2) {
  background-position: 33.33333%;
  transition-delay: 0.1s;
}
.image-container6 .image-layout span:nth-child(3) {
  background-position: 66.66666%;
  transition-delay: 0.2s;
}
.image-container6 .image-layout span:nth-child(4) {
  background-position: 100%;
  transition-delay: 0.3s;
}

.image-container6:hover .image-layout > span, .image-container6.hover .image-layout > span {
  transform: translateY(-100%);
}
.image-container6:hover .content-layout, .image-container6.hover .content-layout  {
  transform: translateY(0%);
  transition: 1s;
  transition-delay: 0.1s;
}

.image-container6 .content-layout {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  padding: 20px 20px;
  width: 100%;
  height: 100%;
  transform: translateY(100%);
}