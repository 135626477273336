#team-member{
	min-height: 30vh;
}

#team-member .page-content{
	width: 100vw;
}


#about-us{
	min-height:30vh;
}

.about-us .page-padding, .team-member .page-padding, .contact-us .page-padding{
	padding: 20px 0 0;
}

.about-us> .page-padding{
	padding-top: 64px;
}
#team-member .app-description{
	letter-spacing: 1px;
	font-weight: 500;
}
